<template>
  <s-dialog
    class="common-tips"
    :visible="visible"
    :append-to-body="appendToBody"
    :close-on-click-modal="closeOnClickModal"
    :show-close="showClose"
    @close-from-icon="closeFromIcon"
    @close-from-mask="closeFromMask"
  >
    <template #title>
      <div
        :class="['common-tips__title', { left: isTitleLeft }]"
        v-html="title"
      ></div>
    </template>
    <div
      :class="['common-tips__content', { center: isContentCenter }]"
      v-html="content"
    ></div>
    <template #footer>
      <s-button
        :type="btnType"
        :width="'100%'"
        @click="clickBtn"
      >
        {{ btnText }}
      </s-button>
    </template>
  </s-dialog>
</template>

<script setup>

defineProps({
  title: {
    type: String,
    default: ''
  },
  content: {
    type: String,
    default: ''
  },
  btnText: {
    type: String,
    default: ''
  },
  btnType: {
    type: Array,
    default() {
      return ['primary']
    }
  },
  visible: {
    type: Boolean,
    default: false
  },
  isTitleLeft: {
    type: Boolean,
    default: false,
  },
  isContentCenter: {
    type: Boolean,
    default: false
  },
  appendToBody: {
    type: Boolean,
    default: false
  },
  closeOnClickModal: {
    type: Boolean,
    default: true
  },
  showClose: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['update:visible', 'close-from-icon', 'close-from-mask', 'ok'])
const updateVisible = event => {
  emit('update:visible', event)
}
const closeFromIcon = event => {
  updateVisible(false)
  emit('close-from-icon', event)
}
const closeFromMask = event => {
  updateVisible(false)
  emit('close-from-mask', event)
}
const clickBtn = event => {
  updateVisible(false)
  emit('ok', event)
}
</script>
<style lang="less">
.common-tips {
  &__title {
    &.left {
      text-align: left;
    }
  }
  &__content {
    &.center {
      text-align: center;
    }
  }
}
</style>
